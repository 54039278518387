import React, { useState } from 'react'
import '../Styles/feedback.css'
import emailjs from 'emailjs-com';
import { useRef } from "react";

function FeedBack() {

  const [formInputs, setFormInputs] = useState({
    name: '',
    email: '',
    tel: '',
    subject: '',
    message: ''
  })

  const handleInputs =(e)=> {

    setFormInputs (prev=> ({
      ...prev, 
      [e.target.name]: e.target.value
    }))
  }

  const [isLoading, setIsLoading] = useState(false)
  setTimeout(() => { setIsLoading(false); }, 9000)

  const form = useRef()
    const sendEmail = (e) => {
        setIsLoading(true);
        e.preventDefault();
    
        emailjs.sendForm('service_b4tvqsm', 'template_x4iut3s', form.current, 'hMKsvlmpbZfpK2Ajk')
          .then((result) => {
            alert("Message Sent")   

          }, (error) => {
            alert("Message NOT Sent")             
          });
          e.target.reset()
         
  }
  
  return (
    <div className='feedback-container'>
      <div className="feedback-text">
        <h2>Request a Quote Today</h2>
        <h3>Reach Us</h3>
        <h4>Location:</h4>
        <p>Conveniently located at 15 Bori Road, Rumuibekwe, Port Harcourt.</p>
        <h4>Email:</h4>
        <p>Contact@earthospitality.com</p>
        <h4>Tel:</h4>
        <p>+234 818 2900 927</p>

      </div>
      <div className="feedback-form">
        <form  ref={form} onSubmit={sendEmail}>
            <input onChange={handleInputs} type="text" name='name' placeholder='Full Name' required/>
            <input onChange={handleInputs} type="email" name='email' placeholder='Email'required />
            <input onChange={handleInputs} type="number" name='tel' placeholder='Contact Number'required />
            <input onChange={handleInputs} type="text" name='subject' placeholder='Purpose of Contact'required />
            <textarea onChange={handleInputs}  rows="5" cols="30" name="message"  placeholder='Your Message'></textarea>
            <input type='submit' placeholder='submit'className='submit-btn'  value={isLoading ? "Submitting..." : "Submit"} />
         
        </form> 
      </div>

      

       

    </div>
  )
}

export default FeedBack