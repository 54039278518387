import React from 'react'
import '../Styles/footer.css'
import Logo from '../Assets/ehl-logo-white.png'
import Linkedin from '../Assets/linkedin.svg'
import X from '../Assets/X-logo.svg'
import Facebook from '../Assets/facebook.svg'
import Instagram from '../Assets/instagram.svg'
import { Link } from 'react-router-dom'

function Footer() {
  const getYear = new Date().getFullYear()
  return (
    <div className='footer-container'>
        <div className="footer-logo">      
            <Link to='/Home'>
             <img src={Logo} alt="baansure-logo" /></Link>
        </div>   
        <div className="useful-links">   
          <Link to='/'>Home</Link>
          <Link to='/About'>About Us</Link>
          <Link to='/Faq'>FAQ</Link>
          <Link to='/Services'>Services</Link>
          <Link to='/Contact'>Contact</Link>         
        </div>   
        <div className="social-icons">
          <a href="https://www.linkedin.com/company/106011239/admin/dashboard/"target='_blank' rel='noreferrer'><img src={Linkedin} alt="linkedin-logo" /></a>
          <a href="https://www.facebook.com/profile.php?id=61571443423780"target='_blank' rel='noreferrer'> <img src={Facebook} alt="facebook-logo" /></a>
          <a href="https://www.instagram.com/earthhospitalitylimited/"target='_blank' rel='noreferrer'><img src={Instagram} alt="instagram-logo" /></a>            
        </div>    

        <div className="copy-right">
          <p>Copyright &copy; {getYear} EHL</p>     
          <p>Powered by earthospitality.com </p>
        </div>            
    

           
     
    </div>
        

  )
}

export default Footer