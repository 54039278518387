import React from 'react'
import Logo from '../Assets/ehl-logo-white.png'
import Hamburger from '../Assets/ham.svg'
import Close from '../Assets/close.svg'
import { Link } from 'react-router-dom'
import '../Styles/nav.css'
import { useState } from 'react'

function Nav() {
    const [showNav, setShowNav] = useState(false)

    const navToggler = ()=> {
        setShowNav(!showNav)    }

    const removeNav = ()=> {
        setShowNav(false)
    }
    
  return (
    <div className='nav-container'>
        <div className="logo">
            <Link to= '/Home'>
             <img  onClick={removeNav} src={Logo} alt="baansure-logo" /></Link>
           
        </div>
        <div className="hamburger">
            {!showNav && <img onClick={navToggler} src={Hamburger} alt="hamburger" />}
            {showNav && <img onClick={navToggler} className='nav-close' src={Close} alt="hamburger" />}
        </div>
        <div className= {showNav? 'mobile-view':'desktop-view'}>
            <Link onClick={removeNav} to='/Home'>Home</Link>
            <Link onClick={removeNav} to='/About'>About Us</Link>            
            <Link onClick={removeNav} to='/Services'>Services</Link>
            <Link onClick={removeNav} to='/Faq'>FAQ</Link>
            <Link onClick={removeNav} to='/Contact'>Contact</Link>
           
        </div>      

    </div>





  )
}

export default Nav